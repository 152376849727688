.container {
  height: auto;
  width: auto;
  /* border: 1px solid blue; */
}

.svgDiv {
  position: relative;
}

.svgIMG {
  position: absolute;
  top: -70px
}

@media screen and (max-width:330px) {
  .svgIMG {
    top: -50px
  }

}