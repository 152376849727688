.H-cont{
    width: 100%;
    height: 20px;
    background-color: #CDD2F6;
    /* border: 2px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
} 

.left .time{
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 1px;
    /* border: 2px solid green; */
}
.right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid green; */
}
.right .head-img {
    /* margin: 0px 3px; */
    padding: 5px 3px;
}